<template>
  <div class="form-handler">
    <component
      :is="formComponentName"
      v-bind="componentProps"
      @create="onCreateUser"
      @update="onUpdateUser"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const CourierForm = () => import('@components/CourierForm')
const UserForm = () => import('@components/UserForm')

export default {
  name: 'form-handler',
  components: {
    CourierForm,
    UserForm
  },
  props: {
    formComponentName: {
      type: String,
      required: true
    },
    formComponentUserPropName: {
      type: String,
      required: true
    },
    selectedUser: {
      type: Object,
      default: null
    },
    createUserListenerName: {
      type: String,
      required: true
    },
    updateUserListenerName: {
      type: String,
      required: true
    },
    createSuccessMessage: {
      type: String,
      default: null
    },
    updateSuccessMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      errorNotification: null
    }
  },
  computed: {
    ...mapGetters('api', [
      'getErrorMessage'
    ]),
    componentProps() {
      return {
        [this.formComponentUserPropName]: this.selectedUser
      }
    },
    createUserRequest() {
      return `${this.createUserListenerName}Request`
    },
    updateUserRequest() {
      return `${this.updateUserListenerName}Request`
    },
    createUserError() {
      return this.getErrorMessage(this.createUserListenerName)
    },
    updateUserError() {
      return this.getErrorMessage(this.updateUserListenerName)
    }
  },
  watch: {
    createUserError() {
      this.closeNotification()
      if (this.createUserError) {
        this.errorNotification = this.openErrorNotification({
          message: this.createUserError
        })
      }
    },
    updateUserError() {
      this.closeNotification()
      if (this.updateUserError) {
        this.errorNotification = this.openErrorNotification({
          message: this.updateUserError
        })
      }
    }
  },
  methods: {
    closeNotification() {
      if (this.errorNotification) {
        this.errorNotification.close()
      }
    },
    onCreateUser(user) {
      this.addApiListeners(this.createUserListenerName, this.onCreateUserComplete)
      this.$store.dispatch(this.createUserRequest, user)
    },
    onUpdateUser(user) {
      this.addApiListeners(this.updateUserListenerName, this.onUpdateUserComplete)
      this.$store.dispatch(this.updateUserRequest, {
        userId: user.id,
        [this.formComponentUserPropName]: user
      })
    },
    onCreateUserComplete() {
      this.removeApiListeners(this.createUserListenerName, this.onCreateUserComplete)
      if (!this.createUserError) {
        this.$parent.close()
        this.openToast({
          message: this.createSuccessMessage || this.$t('users.actions.createSuccess'),
          type: 'is-success'
        })
      }
    },
    onUpdateUserComplete() {
      this.removeApiListeners(this.updateUserListenerName, this.onUpdateUserComplete)

      if (!this.updateUserError) {
        this.$parent.close()
        this.openToast({
          message: this.updateSuccessMessage || this.$t('users.actions.updateSuccess'),
          type: 'is-success'
        })
      }
    }
  }
}
</script>
